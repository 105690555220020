
    import TableSettings from "@/components/tables/table-settings.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            TableSettings,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {
        // Fields
        private readonly settings = {
            // tslint:disable
            buildSettings: [
                {
                    setting:       'Texture Compression',
                    valueIcon:     '',
                    valueText:     'ASTC',
                    description:   'ASTC compression provides the best balance between quality and file size. Regardless of what is set here, it is possible to override the compression setting on individual textures. Textures used for UI might need to be set to uncompressed to keep them crisp.',
                },
            ],

            playerSettingsResolution: [
                {
                    setting:       'Use 32-bit Display buffer',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   '',
                },
            ],

            playerSettingsOther: [
                {
                    section:       'Player Settings > Other Settings >',
                },
                {
                    setting:       'Color Space',
                    valueIcon:     '',
                    valueText:     'Linear',
                    description:   'A linear color space produces more realistic looking lighting.',
                },
                {
                    setting:       'Auto Graphics APIM',
                    valueIcon:     'not_interested',
                    valueText:     '',
                    description:   'Instead tick Auto Graphics API, set the Graphics API to OpenGLES 3. OpenGLES 3 is supported on all Mobile devices Oculus VR currently runs on. Other settings, such as linear color space require OpenGLES 3 as the graphics API.',
                },
                {
                    setting:       'Multithreaded Rendering',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'Multithreaded Rendering will move graphics API calls from the main thread to a separate worker thread. The effects of this will be most noticeable for applications with high CPU usage.',
                },
                {
                    setting:       'Static Batching',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'Static batching will combine meshes marked as static into one big mesh so long as they share the same material. When multiple meshes are batched together, they can be drawn with a single draw call. This comes at the cost of additional memory and storage space. Turning static batching on is recommended.',
                },
                {
                    setting:       'Dynamic Batching',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'Similar to static batching, dynamic batching combines non-static meshes into one big mesh. For meshes to dynamically batch, they need to have the same material, contain less than 900 vertex attributes (this often translates to as little as 300 vertices), and not have multi-pass shaders.' + 
                                   'There is some CPU overhead for every vertex that is dynamically batched. Because the criteria for dynamic batching is so strict and because there is a CPU cost associated with dynamic batching, dynamic batching might need to be turned off. However, in most cases turning this dynamic batching on is recommended.',
                },
                {
                    setting:       'GPU Skinning',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'GPU skinning is supported for VR applications running with OpenGLES 3. GPU Skinning will move the skinning of an animated mesh to the GPU, this should free up CPU resources.',
                },
                {
                    setting:       'Graphics Jobs',
                    valueIcon:     'not_interested',
                    valueText:     '',
                    description:   'Currently, graphics jobs do not work with Dynamic batching.',
                },
                {
                    setting:       'Virtual Reality Supported',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'Oculus needs to be added to the list of supported Virtual Reality SDKs. When an application is submitted to the Oculus store, Oculus should be the ONLY Virtual Reality SDK in this list.',
                },
                {
                    setting:       'Stereo Rendering Method',
                    valueIcon:     '',
                    valueText:     'Single Pass',
                    description:   'This is one of the most important options and should be set to Single Pass. When Single Pass Stereo Rendering (also called Multiview) is enabled, instead of duplicating draw calls for each eye buffer, objects are rendered once to the left eye buffer, then duplicated to the right buffer automatically. When an object is duplicated to the right eye buffer, the appropriate modifications for vertex position and view-dependent variables such as reflection are applied.',
                },
                {
                    setting:       'Minimum API Level',
                    valueIcon:     '',
                    valueText:     'API Level 21',
                    description:   'All Android based HMDs that support Oculus should be running on API Level 21, Lollipop or above. Set the minimum API Level to at least 21.',
                },
                {
                    setting:       'Scripting Backend',
                    valueIcon:     '',
                    valueText:     'IL2CPP',
                    description:   'IL2CPP is going to run faster on device, but it will make build times longer.',
                },
                {
                    setting:       'Prebake Collision Meshes',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'This will increase the project build size but, decrease the load time for levels. If the apk size is not an issue, it is a good idea to check this box.',
                },
                {
                    setting:       'Keep Loaded Shaders Alive',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'Selecting this option will load all shaders on startup. It will increase the initial application load time, but any hitches due to on demand shader compilation should be resolved. If compiling shaders on demand is an issue, check this box.',
                },
                {
                    setting:       'Enable Internal Profiler',
                    valueIcon:     'not_interested',
                    valueText:     '',
                    description:   'Only check when profiling an app, the information provided can be useful to determine what to optimize. Profiler information will be printed to the Android console.',
                },
                {
                    setting:       'Optimize Mesh Data',
                    valueIcon:     'check',
                    valueText:     '',
                    description:   'This will remove any vertex data (tangents, normals, colors, UV, etc...) from a mesh that is not referenced by materials using the mesh. Enabling this option can help reduce the final apk size.',
                },
            ],

            projectSettingsAudio: [
                {
                    section:       '',
                    valueIcon:     '',
                    valueText:     '',
                    description:   'For best immersion, we recommend all games should spatialize audio.',
                },
                {
                    setting:       'DSP Buffer Size',
                    valueIcon:     '',
                    valueText:     'Good latency',
                    description:   '',
                },
                {
                    setting:       'Spatializer Plugin',
                    valueIcon:     '',
                    valueText:     'Oculus Spatializer',
                    description:   'https://developer.oculus.com/downloads/package/oculus-spatializer-unity/',
                },
            ],

            projectSettingsQuality: [
                {
                    section:       '',
                    valueIcon:     '',
                    valueText:     '',
                    description:   'To make sure mobile builds always use a consistent quality setting, uncheck all the levels, except for Simple for the android build. Simple is a good one to start from, it should be the only level that is checked. Because a Scriptable Render Pipeline (URP) is in use, some settings will be hidden or present in the "Universal Render Pipeline Asset" settings.',
                },
                {
                    setting:       'Pixel Light Count',
                    valueIcon:     '',
                    valueText:     '1',
                    description:   'Pixel light count should be 1. If possible, try to use vertex lit shaders, in which case pixel light count could be set to 0.',
                },
                {
                    setting:       'Anisotropic Textures',
                    valueIcon:     '',
                    valueText:     'Per Texture',
                    description:   '(Quest 2 may support this?) Anisotropic Textures should be disabled; ARM Mali GPUs do not support Anisotropic filtering.',
                    linkURL:       'https://www.panic.world/oculus-quest-2-vs-oculus-quest-graphics-comparison-heres-how-much-better-it-is/',
                    linkText:      'Oculus Quest 2 vs. Oculus Quest graphics comparison',
                },
                {
                    setting:       'Anti Aliasing',
                    valueIcon:     '',
                    valueText:     '4x Multi Sampling',
                    description:   'Should generally be set to 4x Multi Sampling. If this has a large enough impact on performance, using 2x is also acceptable. The “Use Recommended MSAA Level” setting in OVRManager will override this setting when enabled.',
                    linkURL:       'https://developer.oculus.com/documentation/native/android/mobile-msaa-analysis/?locale=en_GB',
                    linkText:      'Oculus mobile MSAA Analysis',
                },
                {
                    setting:       'Skin Weights',
                    valueIcon:     '',
                    valueText:     '2 Bones',
                    description:   'Should not be set to more than 2 bones',
                },
                {
                    setting:       'V Sync',
                    valueIcon:     '',
                    valueText:     'Dont Sync',
                    description:   'Should be set to "Dont Sync". The oculus runtime handles its own V Sync.',
                },
            ],

            projectSettingsGraphics: [
                {
                    setting:       '',
                    valueIcon:     '',
                    valueText:     '',
                    description:   'Graphics on all tier levels should use Low for the standard shader quality. Avoid using the standard shaders and use URP/mobile shaders instead.',
                },
                {
                    setting:       '',
                    valueIcon:     '',
                    valueText:     '',
                    description:   'The rendering path for all tier levels should be set to Forward. The performance cost of deferred rendering is too high to make it a viable option on mobile.',
                },
                {
                    setting:       '',
                    valueIcon:     '',
                    valueText:     '',
                    description:   'Real Time GI CPU Usage should be set to Low for all tiers.',
                },
            ],

            headers: [
                {
                    text:       'Setting',
                    align:      'left',
                    sortable:   false,
                    value:      'setting',
                },
                { text: 'Value',       value: 'valueIcon',    sortable:   false },
                { text: 'Description', value: 'description',  sortable:   false },
            ],
            // tslint:enable
        };

        // Data function
        private data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                unityIconPathAndName:
                    require("@/assets/images/unity.png"),

                unitySettingsImagePathAndName:
                    require("@/assets/images/unity_xr_settings.png"),

                urpSettingsImagePathAndName:
                    require("@/assets/images/urp_quest_quality_settings.png"),
            };
        }
    }
